import React, { useState, useEffect } from 'react';
import './App.css'; // Import CSS file for styling
import './styles/fancyText.css'; // Import CSS file for styling
import './styles/valuedServices.css';
import './styles/overlaySection.css';
import './styles/collection.css';
import './styles/insight.css';

// import home1 from './images/main/home-remodel-services-1800.jpg';
// import home2 from './images/main/lakefront-living-specialists-1800.jpg';
// import home3 from './images/main/lake-michigan-cottages-1800.jpg';
// import home4 from './images/main/sea-breeze.jpg';
// import home5 from './images/main/the-dunegrass.jpg';
// import home6 from './images/main/building-1.jpg';
// import home7 from './images/main/building-2.jpg';
// import home8 from './images/main/building-3.jpg';
// import home1 from './images/main-1/1.jpg';
// import home2 from './images/main-2/2.jpg';
import home3 from './images/main-2/3.jpg';
import home4 from './images/main-2/4.jpg';
import home5 from './images/main-2/5.jpg';
import home6 from './images/main-2/6.jpg';
import home7 from './images/main-2/7.jpg';
import home8 from './images/main-2/8.jpg';
import home9 from './images/main-2/9.jpg';
import home10 from './images/main-2/10.jpg';
import home11 from './images/main-2/11.jpg';
import home12 from './images/main-2/12.jpg';


import overlay1 from './images/overlay/architectural-plan-design.jpg';
import overlay2 from './images/overlay/benchmark-wood-shop.jpg';
import overlay3 from './images/overlay/customhomes.jpg';
import overlay4 from './images/overlay/home-remodel-services1.jpg';
import overlay5 from './images/overlay/interior-design.jpg';
import overlay6 from './images/overlay/msbpm.jpg';

import collection1 from './images/collection/msbpmc.jpg';
// import { useHistory } from 'react-router-dom';

import './styles/Footer.css'; // Import CSS file for styling
// import CompanyLogo from './images/msbpm.jpg'; // Import your company logo

const Header = ({ toggleMenu }) => {
  return (
    <header className="header">
      <div className="header-title">
        <h1 className="header-title-main">Hanuma Developers and Construction</h1>
        <h3 className="header-sub-title">BUILDERS INC.</h3>
      </div>
      <nav className="menu">
        <ul>
          <li>Home</li>
          <li>Contact Us</li>
          <li>Testimonies</li>
          <li>Services</li>
        </ul>
      </nav>
    </header>
  );
};

const PhotoSection = ({ photos }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % photos.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [photos.length]);

  const handleClick = (index) => {
    setCurrentIndex(index);
  };

  return (
    <section className="photo-section">
      <div className="photo-container">
        {photos.map((photo, index) => (
          <div className={`slide ${index === currentIndex ? 'active' : ''}`} key={index}>
            <img src={photo} alt={`Photo ${index + 1}`} className="slide-image" />
            <div className="circles">
              {photos.map((_, index) => (
                <span
                  key={index}
                  className={index === currentIndex ? 'active' : ''}
                  onClick={() => handleClick(index)}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

const FancyTextSection = () => {
  // const history = useHistory();

  // const redirectToMoreAboutUs = () => {
  //   history.push('/pages/MoreAboutUs'); // Replace '/another-page' with the URL of the page you want to redirect to
  // };

  return (
    <section className="fancy-text-section">
      <div className="fancy-text">
        <p className="welcome">Welcome</p>
        <p className="crafting-homes">CRAFTING HOMES FOR</p>
        <p className="discerning-clients">DISCERNING CLIENTS</p>
        <p className="since-1986">SINCE 2005</p>
        <p className="passion-line">The only thing more important than experience is passion.</p>
        <p className="passion-line">We have both.</p>
        
        {/* Add the button */}
        {/* <button className="more-about-us-button" onClick={redirectToMoreAboutUs}>More About Us</button> */}
        <button className="more-about-us-button">More About Us</button>
      </div>
    </section>
  );
};

const ValuedServices = () => {
    return (
      <section className="valued-services-section">
        <div className="valued-services">
          <p className="valued-services-valued">valued</p>
          <p className="valued-services-services">SERVICES</p>
          <p className="valued-services-text">Over three decades of practice alongside the</p>
          <p className="valued-services-text">creativity and drive of some of the world's most</p>
          <p className="valued-services-text">talented designers and craftsman - it's truly the</p>
          <p className="valued-services-best">best of every world.</p>
        </div>
      </section>
      );
  };


  const OverlaySection = ({ photos }) => {
    console.log(photos);
    const [currentIndex, setCurrentIndex] = useState(0);
  
    useEffect(() => {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % photos.length);
      }, 3000);
  
      return () => clearInterval(interval);
    }, [photos.length]);
  
    const handleClick = (index) => {
      setCurrentIndex(index);
    };
  
    return (
      <section className="overlay-slider-section">
        <div className="overlay-slider-container">
          {photos.map((photo, index) => (
            <div className={`overlay-slide ${index === currentIndex ? 'overlay-active' : ''}`} key={index}>
              <img src={photo.url} alt={`Slide ${index + 1}`} className="overlay-slide-image" />
              <div className={`overlay-overlay-box ${index === currentIndex ? 'overlay-active' : ''}`}>
                {Array.isArray(photo.header) ? (
                  photo.header.map((header, idx) => (
                    <h2 key={idx} className={idx === 0 ? 'overlay-first-header' : 'overlay-second-header'}>{header}</h2>
                  ))
                ) : (
                  <h2>{photo.header}</h2>
                )}
                <p>{photo.description}</p>
                <button className="overlay-services-button">SERVICES</button>
              </div>
            </div>
          ))}
          <div className="overlay-circles">
            {photos.map((_, index) => (
              <span
                key={index}
                className={index === currentIndex ? 'overlay-active' : ''}
                onClick={() => handleClick(index)}
              />
            ))}
          </div>
        </div>
      </section>
    );
  };

  const CollectionSection = () => {
    return (
      <section className="collection" style={{ backgroundImage: `url(${collection1})` }}>
        <div className="collection-section">
          <p className="collection-section-collection-text">OUR COLLECTION</p>
          <p className="collection-section-Dovetailing-text">Dovetailing your vision with our expertise,</p>
          <p className="collection-section-every-text">every one of our homes has its own signature style.</p>
          <button className="collection-button">Collection</button>
        </div>
      </section>
    );
  };
  
const InsightSection = ({ testimonies }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonies.length);
    }, 5000); // Adjust the interval time as needed

    return () => clearInterval(interval);
  }, [testimonies.length]);

  const handleClick = (index) => {
    setCurrentIndex(index);
  };

  return (
    <section className="insight">
      <div className="insight-section">
        <p className="insight-section-client-text">client</p>
        <p className="insight-section-collection-text">INSIGHT</p>
        {testimonies[currentIndex].map((line, index) => (
          <p className="insight-section-testimony-text" key={index}>{line}</p>
        ))}
        <div className="insight-circles">
          {testimonies.map((_, index) => (
            <span
              key={index}
              className={index === currentIndex ? 'active' : ''}
              onClick={() => handleClick(index)}
            />
          ))}
        </div>
        <button className="insight-button">more insight</button>
      </div>
    </section>
  );
};

const Footer = () => {
  const handleSubscribe = (e) => {
    e.preventDefault();
    // Handle subscription logic here
  };

  return (
    <footer className="footer">
      <div className="footer-left">
        <div>
          {/* <img src={CompanyLogo} alt="Company Logo" className="company-logo" /> */}
          <p className="company-name">Hanuma Developers and Construction</p>
          <p className="caption">BUILDERS INC.</p>
          <p className="company-address">Hyderabad, Telangana, India</p>
          <p className="company-contact">+91-9866578854</p>
        </div>
      </div>
      <div className="footer-middle">
        <p className="copyright">© 2024 Hanuma Builders. All rights reserved.</p>
      </div>
      <div className="footer-right">
        <div className="subscribe-section">
          <p className="subscribe-text">Subscribe</p>
          <p className="subscribe-info">Sign up to receive exclusive news</p>
          <form onSubmit={handleSubscribe} className="subscribe-form">
            <input type="email" placeholder="Your email" className="email-input" />
            <button type="submit" className="signup-button">Subscribe</button>
          </form>
        </div>
      </div>
    </footer>
  );
};
  

  const App = () => {
    const photos1 = [home3, home4, home5, home6, home7, home8, home9,home10, home11, home12];
    const photos2 = [
      { url: overlay1, header: 'CUSTOM HOMES', description: 'Specializing in original, one-of-a-kind special custom homes that are as spectacular as they are enduring.' },
      { url: overlay2, header: 'REMODELS', description: 'Creating spectacular new spaces that deliver unmatched value and quality.' },
      { url: overlay3, header: ['CUSTOM CABINERY', 'Benchmark Wood Studio'], description: 'A state-of-the-art woodshop and skilled craftsmen offers clients truly custom millwork and cabinery.' },
      { url: overlay4, header: ['DESIGN + BUILD', 'Benchmark design Studio'], description: 'Hire your own architect or work with on of our widely recognized designers Benchmark Design Studio to articulate your vision.' },
      { url: overlay5, header: ['INTERIOR DESIGN', 'Benchmark design Studio'], description: 'From conceptual designs to furnishings, Benchmark Design studio ensures that every inch of your home eximplifies your taste.' },
      { url: overlay6, header: ['PROPERTY MANAGEMENT', 'Hanuma properties management pvt ltd'], description: 'Enjoy complete peace-of-mind with pur year-round maintenance and property management services.' }
    ];
  
    const [showMenu, setShowMenu] = useState(false);
  
    const toggleMenu = () => {
      setShowMenu((prevState) => !prevState);
    };

    const testimonies = [
      ["We had a complicated project and they were very creative and took it","personal to make sure we would be happy with the end result. We think of","them often as we enjoy the many little extra things they came up with and we", "now enjoy from day to day."],
      ["This team of professionals has exceeded our expectations every step along", "the way. The quality of their work and attention to detail is extraordinary. Not", "only would we recommend them without hesitation, we will use them ourselves", "for every additional home project."],
      ["Having had a complete kitchen and bath remodeling done by Hanuma Builders I", "can only give them the highest recommendation for excellent workmanship", "and outstanding responsiveness. Balaji's organizational culture is highly", "customer-centric. Outstanding people."]
    ];
  
    return (
      <div className="app">
        <Header toggleMenu={toggleMenu} />
        <div className="content">
          <PhotoSection photos={photos1} />
          <FancyTextSection />
          <ValuedServices />
          <OverlaySection photos={photos2} />
          <CollectionSection />
          <InsightSection testimonies={testimonies} />
        </div>
        <Footer/>
      </div>
    );
  };
  

export default App;